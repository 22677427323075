import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import AnimatedHero from '../../components/animated-hero'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Text from '../../components/text'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import PatternDots from '../../images/pattern-dots.svg'
import RectangleQuote from '../../components/rectangle-quote'
import React, { useMemo, useState, useEffect } from 'react'
import { boolean, object, string } from 'yup'
import { Field, Form, Formik } from 'formik'
import TextField from '../../components/form-text-field'
import startCase from 'lodash/startCase'
import Checkbox from '../../components/form-checkbox'
import Button from '../../components/button'
import ContactCard from '../../components/contact-card'
import Select from '../../components/form-select'
import Image from '../../components/image'
import { ReactComponent as AlertIcon } from '../../images/icon-alert.svg'
import { formatPayload } from '../../utils/form'
import LinkBanner from '../../components/link-banner'
import VideoPlayer from '../../components/video-player'
import ReactPlayer from 'react-player/youtube'
import { ReactComponent as Play } from '../../images/icon-play-sm-solid.svg'
import LogoFade from '../../components/logo-fade'
import Profiles from '../../components/profiles'
import Banner from '../../components/banner'
import { usePageContext } from '../../context/page-context'
import { encode } from '../../utils/form'
import mapValues from 'lodash/mapValues' 
import isObject from 'lodash/isObject'

const FORM_NAME = 'demo-form'


const getYupSchema = (type) => {
  switch (type) {
    case 'Text':
      return string()
    case 'Email':
      return string()
    case 'Select':
      return object().nullable()
    case 'Checkbox':
      return boolean()
    default:
      return string()
  }
}
const getYupRequired = (schema, type, label) => {
  switch (type) {
    case 'Checkbox':
      return schema.required(label).oneOf([true], label)
    default:
      return schema.required(label)
  }
}

const IndexPage = ({ data: { index }, location }) => {

  const { locales } = usePageContext()
  const { locale } = usePageContext()

  const page = useMemo(
    () =>
      flatten(index || {}, [
        'hero',
        'introduction',
        'introductionQuote',
        'contactHeader',
        'logosHeader',
        'ourLeadersHeader',
        'banner',
        'global',
      ]),
    [index]
  )

  const [success, setSuccess] = useState(null)
  const schema = useMemo(
    () =>
      object().shape(
        page.formFields.reduce((validation, field) => {
          if (field.fieldRequired)
            return Object.assign(validation, {
              [field.fieldName]: getYupRequired(
                getYupSchema(field.fieldType),
                field.fieldType,
                field.fieldRequiredLabel
              ),
            })
          return validation
        }, {})
      ),
    [page]
  )

  const [initialValues, setInitialValues] = useState(useMemo(
    () =>
      page.formFields.reduce((values, field) => {
        switch (field.fieldType) {
          case 'Text':
            return Object.assign(values, { [field.fieldName]: '' })
          case 'Checkbox':
            return Object.assign(values, { [field.fieldName]: false })
          case 'Select':
            return Object.assign(values, { [field.fieldName]: null })
          default:
            return Object.assign(values, { [field.fieldName]: '' })
        }
      }, {}),
    [page]
  ))

  const FieldGroup = styled.div`
    ${tw`relative`}
  `

  const ErrorMessage = styled.div`
    ${tw`absolute flex items-center mt-2 text-sm text-red-500 leading-caption tracking-caption`}
  `
  useEffect(() => {
    const hashtag = window.location.hash
    if (hashtag) {
      const currentURL = window.location.href
      const newURL = currentURL.split("#")[0]
      window.history.replaceState("", "lunch",newURL)
    }
  })

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition headerTransitionColor="white" withNav>
      <section id="hero" css={tw`relative h-78vh lg:(h-screen)`}>
        <AnimatedHero
          animation={get(page, 'hero.animation')}
          animationMobile={get(page, 'hero.animationMobile')}
          title={get(page, 'hero.titleNode')}
          description={get(page, 'hero.descriptionNode')}
          callToAction={get(page, 'hero.callToAction')}
          extended
          isFull={false}
        />
      </section>
      <section id="whoweare" css={tw`relative pt-24 pb-28 lg:(pt-54 pb-30)`}>
        <div
          css={css`
            ${tw`bg-contain absolute inset-0 top-0 h-48 lg:(h-80 bg-auto) xl:h-100`}
            background-image: url('${PatternDots}');
          `}
        />
        <div
          css={[
            global`layout.container`,
            global`layout.grid`,
            css`
              grid-template-rows: max-content;
            `,
          ]}
        >
          <div
            css={tw`order-1 col-span-4 md:col-span-8 lg:(col-start-1 col-end-8) xl:(col-start-2 col-end-10)`}
          >
            <Heading
              headingType="h2"
              content={get(page, 'introduction.titleNode')}
              style={css`
                ${tw`text-primary-500`}
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
          <div
            css={tw`relative order-3 col-span-4 mt-0 md:col-span-8 lg:(mt-48 order-2 row-span-2 col-start-9 col-end-13 self-center) xl:(mt-52 col-start-11 col-end-16) xxl:(mt-32)`}
          >
            <div
              css={css`
                ${tw`relative px-16 py-20 lg:(py-24 pl-8 pr-12) xl:(py-24) xxl:(py-24 pl-12 pr-18)`}
                background-color: #e6f9fa;
              `}
            >
              <Heading
                headingType="h4"
                content={get(page, 'introductionQuote.titleNode')}
                style={css`
                  ${tw`italic text-primary-500`}
                  font-size: 1.75rem;
                `}
              />
              <Text
                content={get(page, 'introductionQuote.subtitleNode')}
                style={[global`typography.body-base`, tw`mt-4 text-primary-400 opacity-70`]}
              />
            </div>
            <RectangleQuote />
          </div>
          <div
            css={tw`my-8 order-2 col-span-4 md:col-span-8 lg:(order-3 col-start-1 col-end-8 mt-12) xl:(mb-0 col-start-3 col-end-9 text-primary-500)`}
          >
            <Text content={get(page, 'introduction.descriptionNode')} />
          </div>
        </div>
      </section>
      <section
        id="logos"
        css={tw`pt-0 pb-8 bg-white lg:(pt-24 pb-12)`}>
        <div css={[global`layout.container`, tw`overflow-hidden lg:w-full`]}>
          <Heading content={get(page, 'logosHeader.titleNode')} headingType="h2" style={tw`text-center text-primary-500 mb-4 lg:mb-12`}/>
        </div>
        <LogoFade 
          logos={get(page, 'logos')}
          style={[tw`mx-container-mobile`, global`layout.desktop-container`]}
        />
      </section>
      {page.video && 
      <section id="video" css={[global`layout.container`, tw`flex z-1 -mb-20 lg:-mb-20`]}>
        <div css={tw`flex-1`}>
          <VideoPlayer
            streamingUrl={get(page, 'video.video.mp4Url')}
            thumbnailUrl={get(page, 'videoPoster.video.mp4Url')}
          />
        </div>
      </section>
      }
        {page.embedVideo &&
          <section id="video" css={tw`relative bg-white pt-14`}>
            <div css={[global`layout.container`, tw`flex xl:(max-w-3/4) xxl:(max-w-xxl)`]}>
              <div css={tw`h-0 pb-ratio-16/9 flex-1 w-full relative z-10 -mb-20`}>
                <ReactPlayer
                  url={get(page, 'embedVideo.url')}
                  light={get(page, 'image.url')}
                  playIcon={<button><Play /></button>}
                  width="100%"
                  height="100%"
                  playing
                  loop
                  controls
                  muted
                  playsinline
                  style={tw`absolute`}
                />
              </div>
            </div>
        </section>
        }


      <section id="ourleaders" css={tw`pt-44 pb-0 md:pb-24 bg-primary-50 lg:(pt-52 pb-30)`}>
        <div css={[global`layout.container`]}>
          <div css={tw`flex justify-center`}>
            <Heading
              content={get(page, 'ourLeadersHeader.titleNode')}
              headingType="h2"
              style={tw`items-center`}
            />
          </div>
          <Profiles
            profiles={get(page, 'ourLeaders')}
            nextLabel={get(page, 'nextLabel')}
            closeLabel={get(page, 'closeLabel')}
            style={tw`mt-16 lg:(mt-14)`}
            profileStyle={tw`last-of-type:-mb-72! lg:(min-w-86 last-of-type:-mb-16!)`}
          />
        </div>
      </section>
      <section id="local-leadership" css={[global`layout.container`, tw`pt-96 pb-20 md:pt-72 lg:(py-30)`]}>
        <Banner
          title={get(page, 'ourLeadersCta[0].titleNode')}
          callToAction={get(page, 'ourLeadersCta[0].callToAction')}
        />
      </section>

      <section id="contact-form" css={tw`bg-primary-50 lg:(pt-30 pb-34)`}>
        <div
          css={[
            global`layout.grid`,
            tw`relative lg:px-container-lg xl:px-container-xl xxl:px-container-xxl`,
          ]}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={async (values, { resetForm, setErrors, setSubmitting }) => {
              setSubmitting(true)
              try {
                const res = await fetch('/', {
                  method: 'post',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: encode({
                    'form-name': FORM_NAME,
                    ...mapValues(values, (v) => (isObject(v) ? v.label : v)),
                  }),
                })
                if (res.status === 200) {
                  resetForm()
                  setSuccess(get(page, 'formSuccessNode'))
                } else resetForm()
              } catch (err) {
                console.error(err)
              }
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue, values }) => (
              <>
                {success && (
                  <>
                  <Heading
                    headingType="h4"
                    content={success}
                    style={tw`col-span-4 md:col-span-8 lg:(col-span-6) xl:(col-span-5) text-primary-500 font-medium py-16 px-8 lg:(px-16 py-32)`}
                  />
                    <div id="ga-thank-you"></div>
                  </>
                )}
                <Form
                  name={FORM_NAME}
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="password"
                  css={[
                    tw`flex flex-col items-start col-span-4 my-24 mx-container-mobile md:col-span-8 lg:(mx-0 my-0 col-span-6)`,
                    success && tw`hidden`,
                  ]}
                >
                  <input type="hidden" name="form-name" value={FORM_NAME} />
                  <input type="hidden" name="subject" value="Request a demo" />
                  <div>
                    <Heading
                      headingType="h3"
                      content={page.formTitle}
                      style={tw`font-bold text-primary-500 lg:whitespace-nowrap`}
                    />
                    <div css={tw`mt-5 lg:(mt-12 mb-6) text-sm font-bold opacity-60`}>
                      {page.formRequiredLabel}
                    </div>
                  </div>
                  <div css={tw`flex flex-col w-full space-y-4`}>
                    {page.formFields.map((field) => {
                      switch (field.fieldType) {
                        case 'Email':
                        case 'Text':
                          if(field.fieldName != 'message'){
                            return (
                              <FieldGroup key={field.fieldName}>
                                <TextField
                                  type="text"
                                  name={field.fieldName}
                                  label={`${field.fieldLabel}${field.fieldRequired ? '*' : ''}`}
                                  invalid={errors[field.fieldName] && touched[field.fieldName]}
                                  value={get(values, field.fieldName)}
                                  onInput={(v) => setFieldValue(field.fieldName, v.target.value)}
                                  aria-required={field.fieldRequired}
                                  aria-label={startCase(field.fieldName)}
                                />
                                {errors[field.fieldName] && touched[field.fieldName] ? (
                                  <ErrorMessage>
                                    <AlertIcon css={tw`mr-2`} />
                                    {errors[field.fieldName]}
                                  </ErrorMessage>
                                ) : null}
                              </FieldGroup>
                            )
                          }else{
                            return (
                              <FieldGroup key={field.fieldName}>
                                <TextField
                                  textarea
                                  name={field.fieldName}
                                  label={`${field.fieldLabel}${field.fieldRequired ? '*' : ''}`}
                                  invalid={errors[field.fieldName] && touched[field.fieldName]}
                                  value={get(values, field.fieldName)}
                                  onInput={(v) => setFieldValue(field.fieldName, v.target.value)}
                                  aria-required={field.fieldRequired}
                                  aria-label={startCase(field.fieldName)}
                                  rows={5}
                                />
                                {errors[field.fieldName] && touched[field.fieldName] ? (
                                  <ErrorMessage>
                                    <AlertIcon css={tw`mr-2`} />
                                    {errors[field.fieldName]}
                                  </ErrorMessage>
                                ) : null}
                              </FieldGroup>
                            )
                          }
                        case 'Select':
                          return (
                            <FieldGroup key={field.fieldName}>
                              <Field
                                as={Select}
                                key={field.fieldName}
                                name={field.fieldName}
                                placeholder={`${field.fieldLabel}${field.fieldRequired ? '*' : ''}`}
                                options={compact(field.fieldOptions.split('\n')).map((o) => ({
                                  label: o,
                                  value: o,
                                }))}
                                hasError={errors[field.fieldName] && touched[field.fieldName]}
                                value={values[field.fieldName]}
                                aria-required={field.fieldRequired}
                                aria-label={startCase(field.fieldName)}
                              />
                              {errors[field.fieldName] && touched[field.fieldName] ? (
                                <ErrorMessage>
                                  <AlertIcon css={tw`mr-2`} />
                                  {errors[field.fieldName]}
                                </ErrorMessage>
                              ) : null}
                            </FieldGroup>
                          )
                        case 'Checkbox':
                          return (
                            <FieldGroup key={field.fieldName} css={tw`mt-8`}>
                              <Checkbox
                                name={field.fieldName}
                                value={values[field.fieldName]}
                                onChange={(v) => setFieldValue(field.fieldName, v.target.checked)}
                                invalid={errors[field.fieldName] && touched[field.fieldName]}
                                aria-required={field.fieldRequired}
                                aria-label={startCase(field.fieldName)}
                              >
                                <Text
                                  style={[
                                    tw`text-base font-light leading-none text-primary-500`,
                                    errors[field.fieldName] &&
                                    touched[field.fieldName] &&
                                    tw`text-red-500`,
                                  ]}
                                  content={field.fieldOptionsNode}
                                />
                              </Checkbox>
                            </FieldGroup>
                          )
                        default:
                          return null
                      }
                    })}
                  </div>
                  <div css={tw`hidden`}>
                    <input type="text" name="password" placeholder="Password" />
                  </div>
                  <Button
                    type="primary"
                    size="base"
                    theme="navy-cyan"
                    buttonType="submit"
                    disabled={isSubmitting}
                    label={page.formButtonLabel}
                    style={tw`mt-14`}
                  />
                </Form>
              </>
            )}
          </Formik>
          <aside
            css={css`
              ${tw`col-span-4 -mb-16 mx-container-mobile md:col-span-8 lg:(ml-container-lg ml-0 mb-0 absolute right-0 w-2/5 bg-transparent -bottom-56)`}
            `}
          >
            <Image image={get(page, 'banner')} />
          </aside>
        </div>
      </section>
      <section id="contactus" css={tw`relative pt-24 pb-22 lg:py-40`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <Heading
            content={get(page, 'contactHeader.titleNode')}
            headingType="h3"
            style={tw`col-span-4 font-bold md:col-span-8 text-primary-500`}
          />
          <div
            css={tw`whitespace-pre flex flex-col col-span-4 space-y-8 mt-12 md:col-span-8 
            lg:(space-y-0 flex-row justify-between mt-24 space-x-28 col-span-12) xl:col-span-14 xxl:col-span-12`}>
            {get(page, 'contactInfo').map((contact, i) => (
              <ContactCard
                key={i}
                label={contact.label}
                contactType={contact.contactType}
                value={contact.valueText}
                subtext={contact.subTextNode}
                style={tw`flex-1`}
              />
            ))}
          </div>
        </div>
      </section>
      <section id="global" css={tw`py-20 bg-secondary-500 xl:py-24`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <LinkBanner
            title={get(page, 'global.titleNode')}
            callToAction={get(page, 'global.callToAction')}
            style={tw`col-span-4 md:col-span-8 lg:(col-start-1 col-end-11)`}
          />
        </div>
      </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default IndexPage

export const query = graphql`
query IndexQuery {
  index: datoCmsNewVnHomepage (locale: { eq: "vi" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        animation {
          format
          url
        }
        animationMobile {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      heroDescription {
        textNode {
          childMarkdownRemark {
            html
          }
        }
      }
      introduction {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      logosHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      logos {
        format
        url
      }
      video {
        format
        video {
          mp4Url
        }
      }
      video {
        format
        video {
          mp4Url
        }
      }
      embedVideo {
        thumbnailUrl 
        url      
      }
      image {
        format
        url
      }
      introductionQuote {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ourLeadersHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ourLeaders {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        name
        jobTitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ourLeadersCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }

      contactHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      contactInfo {
        label
        contactType
        valueText
        subtextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      banner {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      formTitle
      formRequiredLabel
      formFields {
        fieldLabel
        fieldName
        fieldRequired
        fieldRequiredLabel
        fieldType
        fieldOptionsNode {
          childMarkdownRemark {
            html
          }
        }
      }
      formButtonLabel
      formSuccessNode {
        childMarkdownRemark {
          html
        }
      }
      global {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
    }
  }
`
